import React from 'react';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';

interface IProps {
  url: string
  enabled: boolean
  label: string
}

const HomePageMenuItem = ({ url, enabled, label } : IProps) => {
  return (
    <div className={ styles.main }>
      {
        enabled
          ? <>
            {
              (url.startsWith('http') || url.startsWith('/sberboost'))
                ? <a href={url}>{ label }</a>
                : <Link to={ url }>{ label }</Link>
            }
            </>
          : <div>{ label }</div>
      }
    </div>
  );
};

export { HomePageMenuItem };