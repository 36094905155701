import {
  Api,
  // BaseQueryFn,
  createApi, EndpointDefinitions, Module,
  // FetchArgs,
  // fetchBaseQuery,
  // FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
// import {RootState, store} from '../storeToolkit';
// import {index} from '../../api/authorization';
// import jwt_decode from 'jwt-decode';
// import {IUserInfo} from '../constants';
// import {logIn, logOut} from '../slices/authSlice';
// import {BaseQueryApi} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {axiosBaseQuery} from './axiosBaseQuery';
import { surveyApi } from "./survey/survey.api";

// const mutex = new Mutex();
// const baseQueryWithReauth: BaseQueryFn<
// 	string | FetchArgs,
// 	unknown,
// 	FetchBaseQueryError
// 	> = async (args, api, extraOptions) => {
// 	// wait until the mutex is available without locking it
// 	console.log('начало запроса' , new Date().getTime());
// 	await mutex.waitForUnlock()
// 	let result = await baseQuery(args, api, extraOptions)
// 	if (result.error && result.error.status === 401) {
// 		// checking whether the mutex is locked
// 		if (!mutex.isLocked()) {
// 			const release = await mutex.acquire()
// 			try {
// 				const refreshResult = await index.refreshToken();
// 				if (refreshResult) {
// 					const jwtDecoded: any = jwt_decode(refreshResult.refreshToken || '');
// 					const user: IUserInfo = {
// 						name: jwtDecoded?.user_name || 'User',
// 						role: jwtDecoded?.user_type || 'user',
// 						access_token: refreshResult.refreshToken || '',
// 					};
// 					result = await baseQuery(args, api, extraOptions)
// 				} else {
// 					// api.dispatch(loggedOut())
// 				}
// 			} finally {
// 				// release must be called once the mutex should be released again.
// 				release()
// 			}
// 		} else {
// 			// wait until the mutex is available without locking it
// 			await mutex.waitForUnlock()
// 			result = await baseQuery(args, api, extraOptions)
// 		}
// 	}
// 	return result
// }

// const baseQuery = fetchBaseQuery({
//   baseUrl: process.env.NODE_ENV === 'development' ? 'https://tkldw-scs000001.delta.sbrf.ru:10443/api' : '/api',
//   prepareHeaders: (headers) => {
//     const token = (store.getState() as RootState).auth.access_token || '';
//     if (token) {
//       headers.set('authorization', `Bearer ${token}`)
//     }
//     return headers
//   },
// });

// const baseQueryWithTimeout = async (
//   args: string | FetchArgs,
//   api: BaseQueryApi,
//   extraOptions: { timeout?: number } = {timeout: 15000}) => {
//
//   const controller = new AbortController();
//
//   if (extraOptions && extraOptions.timeout) {
//     const timerId = setTimeout(() => {
//       controller.abort();
//       throw new Error('timeout')
//     }, extraOptions.timeout);
//
//     try {
//       const res = await baseQuery(args, {...api, signal: controller.signal}, extraOptions);
//       return res;
//     } finally {
//       clearTimeout(timerId);
//     }
//   }
//   else {
//     return baseQuery(args, api, extraOptions);
//   }
// }

// const baseQueryWithReauth: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
//   > = async (args, api, extraOptions) => {
//   let result = await baseQueryWithTimeout(args, api, extraOptions)
//   if (result.error && result?.error?.status === 401) {
//     try {
//       const refreshResult = await index.refreshToken();
//       // store the new token
//       const jwtDecoded: any = jwt_decode(refreshResult.accessToken || '');
//       const user: IUserInfo = {
//         name: jwtDecoded?.user_name || 'User',
//         role: jwtDecoded?.user_type || 'user',
//         access_token: refreshResult.accessToken,
//       };
//       store.dispatch(logIn(user))
//       // retry the initial query
//       result = await baseQueryWithTimeout(args, api, extraOptions)
//     } catch (e) {
//       if (e?.response?.status === 401) {
//         // неудачная попытка обновить токен
//         // разлогиниваемся и стираем токен
//         await index.logout();
//         store.dispatch(logOut());
//         // window.location.reload();
//         // и снова логинимся, но уже гостем
//         try {
//           const res = await index.getGuestToken();
//           const jwtDecoded: any = jwt_decode(res.accessToken || '');
//           const newUserInfo: IUserInfo = {
//             name: jwtDecoded?.user_name || 'Гость',
//             role: jwtDecoded?.user_type || 'Guest',
//             access_token: res.accessToken,
//           };
//           store.dispatch(logIn(newUserInfo));
//           result = await baseQueryWithTimeout(args, api, extraOptions)
//         } catch(e) {
//           // выкинем "наверх"
//           throw result.error;
//         }
//       }
//     }
//   }
//   return result
// }

export const commonApi = createApi({
  reducerPath: 'application/api',
  refetchOnFocus: false,
  refetchOnReconnect: false,
  baseQuery: axiosBaseQuery,
  tagTypes: ['Meeting','QuestionBoards', 'Question', 'Votes', 'Members', 'Activities', 'Project', 'likes','IdeaSchema', 'Discussion', "PageItems", 'Notifications', 'NotificationsCount'],
  endpoints: _ => ({})
})